import { dataStorage } from "./global/dom";

/** @type {IntersectionObserverCallback} */
function intersectionHandler(entries) {
	for (let entry of entries) {
		let element = entry.target
		let target = element;
		if (element.dataset.visibilityObserverTarget) {
			target = document.querySelector(element.dataset.visibilityObserverTarget);
		}

		let classesShown = element.dataset.classesWhenShown?.split(' ').filter(e => e) || [];
		let classesVisible = element.dataset.classesWhenVisible?.split(' ').filter(e => e) || [];

		if (entry.isIntersecting) {
			if (!target.hasAttribute('data-shown')) {
				target.dispatchEvent(new Event("tu.shown", { bubbles: true }));
				target.setAttribute('data-shown', '');
				target.classList.add(...classesShown);
			}
			
			target.setAttribute('data-visible', '');
			target.classList.add(...classesVisible);
		} else {
			target.removeAttribute('data-visible');
			target.classList.remove(...classesVisible);
		}
	}
}

/**
 * 
 * @param {HTMLElement} element 
 */
function observeElement(element) {
	let threshold = Number(element.dataset.threshold) || 0;

	/** @type {IntersectionObserver} */
	let intersectionObserver = dataStorage.get(element, 'intersection-observer');
	if (intersectionObserver) intersectionObserver.disconnect();

	intersectionObserver = new IntersectionObserver(intersectionHandler, { threshold });
	dataStorage.put(element, 'intersection-observer', intersectionObserver);

	intersectionObserver.observe(element);

	return intersectionObserver;
}

/**
 * 
 * @param {HTMLElement} element 
 */
function initIntersectionObserver() {
	for (let el of document.querySelectorAll('[data-observe-visibility], [data-classes-when-shown], [data-classes-when-visible]')) {
		observeElement(el);
	}
}

export { initIntersectionObserver };
